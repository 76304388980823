<template>
  <div>
    <title-bar :show-home="false" />
    <div class="columns">
      <div class="column">
        <header-user />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <CategoriasDashboards />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderUser from "../components/HeaderUser";
import CategoriasDashboards from "../components/CategoriasDashboards/Index.vue"
export default {
  components: {
    HeaderUser,
    CategoriasDashboards,
  },
};
</script>
