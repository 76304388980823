<template>
  <div class="box">
    <h1 class="is-size-4 is-inline has-text-weight-semibold">
      Favoritos y frecuentes
    </h1>
    
    <div class="columns" v-if="loading_categories">
      <div class="column is-3" v-for="l in [1,2,3,4]" :key="l">
        <div class="box">
          <b-skeleton  height="100px"></b-skeleton>
          <b-skeleton ></b-skeleton>
        </div>
      </div>
    </div>
    <div class="mt-2 columns is-flex-wrap-wrap" v-else>
      <div class="column is-3" v-for="(item,k) in categories" :key="k">
        <div class="box is-clickable" @click="handleClick(item.dashboards[0])">
          <div class="box-child is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between">
            <b-icon icon="chart-pie" type="is-primary" size="is-large-icon"></b-icon>
            <div class="is-size-4">
              {{item.nombre}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalFormCategorie />
  </div>
</template>
<script>
import ModalFormCategorie from "@/modules/form/1617206897966/components/nuevo/Modal";
export default {
  components: {
    ModalFormCategorie,
  },
  computed:{
    loading_categories(){
      return this.$store.getters[`form_1617206897966/loading`].favorites || false
    },
    categories(){
      return this.$store.getters[`form_1617206897966/favorites`];
    },
  },
  methods: {
    handleClick(row){
      this.$router.push(`/form/1623469803663/${row.id}`)
    }, 
  },
  created(){
    this.$store.dispatch(`form_1617206897966/favorites`)
  }
}
</script>
<style scoped lang="scss">
/deep/ .is-large-icon{
  height: 6rem;
  width: 6rem;
  svg {
    width: auto;
    height: auto;
  }
}

.box-child{
  height: 150px;
}
</style>