<template>
  <section class="hero is-hero-bar is-main-hero">
    <div class="hero-body">
      <div class="level">
        <div class="level-left">
          <div class="level-item is-hero-avatar-item">
            <div class="image is-user-avatar">
              <avatar-user responsive rounded />
            </div>
          </div>
          <div class="level-item is-hero-content-item">
            <div>
              <h1 class="title is-spaced">Hola, {{ full_name }}</h1>
              <h3 class="subtitle mb-3">Encuentre y comparta conclusiones útiles para tomar decisiones controladas por datos.</h3>
              <p class="is-italic">Hoy: {{ date_to_string }}</p>
            </div>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-button 
              type="is-info is-light" 
              icon-left="plus"
              @click="addCategorie">
              Nuevo informe
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import AvatarUser from "../../auth/components/AvatarUser.vue";
export default {
  components: { AvatarUser },
  computed: {
    ...mapGetters({
      full_name: "auth/full_name",
    }),
    date_to_string() {
      return new Date().toLocaleDateString();
    },
  },
  methods: {
    addCategorie(){
      this.$store.commit(`form_1617206897966/SET_ROW_EDIT`)
      this.$store.commit(`form_1617206897966/SET_MODAL`, {form: true})
    },
  }
};
</script>

<style lang="scss" scoped>
section.hero.is-hero-bar.is-main-hero {
  background-image: url("../../../assets/images/trianglify-bw-50@2x.png");
  background-size: cover;
}
section.hero.is-hero-bar {
  background-color: #fff;
  border-bottom: 1px solid rgba(24, 28, 33, 0.06);
}
</style>
