<template>
  <modal-base 
    v-model="show" 
    :title="title"
    @after-enter="onMain">
    <Form 
      v-model="form"
      @oncancel="show = !show"
      @onsuccess="show = !show" />
  </modal-base>
</template>
<script>
import Form from "./Form.vue"
import { nameStoreForm } from "@/utils/helpers";
const name_store = nameStoreForm( require.context("../../", false, /\.name$/i) );
// 
export default {
  components:{
    Form,
  },
  data(){
    return {
      form: {}
    }
  },
  computed:{
    show:{
      get(){
        return this.$store.getters[`${name_store}/modal`].form ?? false;
      },
      set(e){
        this.$store.commit(`${name_store}/SET_MODAL`, {form: e})
      }
    },
    title(){
      return (!this.rowEdit ? `Crear` : `Editar`) + ` Informe`
    },
    rowEdit(){
      return this.$store.getters[`${name_store}/rowEdit`]
    }
  },
  methods: {
    onMain(){
      if(this.rowEdit){
        this.$set(this, 'form', ...this.rowEdit)
      } else {
        this.$set(this, 'form', {})
      }
    }
  }
}
</script>