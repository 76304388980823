<template>
  <b-image :src="src" v-bind="$attrs" style="max-width:120px" />
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
      imagen_url: "auth/imagen_url"
    }),
    src() {
      if(this.imagen_url) return this.imagen_url
      // 
      let src = require("@/assets/images/user-men.svg");
      if (this.user) {
        const { sexo } = this.user.persona;
        if (sexo === "F") src = require("@/assets/images/user-women.svg");
      }
      return src;
    },
  },
};
</script>
